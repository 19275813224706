import {
  Main,
  FirstContainer,
  SecondContainer,
  BackgroundImage,
  ImageInContainer,
  TextImageContainer,
  TextInContainer,
  TextInContainerHeader,
  ContactContainer,
} from "./page.styles";
import {
  HeadingOneShadow,
  BodyThreeText,
  HeadingTwo,
  LinkArrowContentPage,
  HeadingOne,
  LinkOne,
} from "../ui/text.styles";
import { mdiArrowRightCircle, mdiChevronDown } from "@mdi/js";
import Icon from "@mdi/react";
import { scrollDown } from "../utils/ClickFunctions";

export const Culture = () => {
  return (
    <Main>
      <BackgroundImage image={`${"/images/skiing.jpg"}`} />
      <FirstContainer>
        <TextInContainerHeader>
          <HeadingOneShadow>Culture</HeadingOneShadow>
          <BodyThreeText>
            We see Nomad as more than just a workplace. It is a place for sharing knowledge, extending networks, making
            new friends and building something better, together. We do things together as often as we can, something
            that is encouraged rather than forced. Activities range from conferences in Sweden or abroad, sun-bathing or
            skiing, diving or running, spa weekends, concerts, parties, dinners or just a simple beer on a Friday
            afternoon (and everything in between).
          </BodyThreeText>
        </TextInContainerHeader>
        <LinkArrowContentPage onClick={scrollDown}>
          <Icon path={mdiChevronDown} size={3} />
        </LinkArrowContentPage>
      </FirstContainer>
      <SecondContainer>
        <div>
          <HeadingTwo>Nomad Culture</HeadingTwo>
          <TextImageContainer>
            <div>
              <TextInContainer>
                <BodyThreeText>
                  At Nomad you can be certain to be seen and listened to. No one will ever be forced to take or stay on
                  an assignment longer than they want to. We intend to hire only highly skilled individuals. But we
                  don’t count working years or the number of assignments on your CV. It is about who you are and what
                  you want to do.
                </BodyThreeText>
                <BodyThreeText>
                  We value social competence over technical knowledge, because we know that social competence is one of
                  the key factors for long-term success among us Nomads and with our customers. At Nomad we believe in
                  equality: Diversity is beneficial in all forms and flavors and should be promoted: It’s about
                  accepting and valuing all people, regardless of race, gender, sexual orientation, religion, age etc.
                </BodyThreeText>
              </TextInContainer>
            </div>
            <ImageInContainer src={"/images/after-work.jpg"} alt="logo" />
          </TextImageContainer>
        </div>
        <ContactContainer>
          <HeadingOne>Want to know more and/or become a Nomad? </HeadingOne>
          <LinkOne to={{ pathname: `/contact` }}>
            Contact us <Icon path={mdiArrowRightCircle} size={1} />
          </LinkOne>
        </ContactContainer>
      </SecondContainer>
    </Main>
  );
};

export default Culture;
