import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import LogoSmall from "../../layout/logo/LogoSmall";
import { LinkOne } from "../../ui/text.styles";
import HamburgerContainer from "../Hamburger-menu/HamburgerContainer";
import { StyledHeader } from "./header.style";

interface IHeader {
  isLargeLogoVisible: boolean;
  setIsLargeLogoVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const Header: React.FC<IHeader> = (props: IHeader) => {
  const { isLargeLogoVisible, setIsLargeLogoVisible } = props;
  const location = useLocation();

  const listenToScroll = () => {
    if (location.pathname === "/") {
      let heightToHideTo = window.innerHeight - window.innerHeight * 0.5;
      const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

      if (winScroll > heightToHideTo) {
        setIsLargeLogoVisible(false);
      } else {
        setIsLargeLogoVisible(true);
      }
    }
  };

  useEffect(() => {
    if (location.pathname === "/") {
      setIsLargeLogoVisible(true);
      window.addEventListener("scroll", listenToScroll);
      return () => {
        window.removeEventListener("scroll", listenToScroll);
      };
    } else {
      window.removeEventListener("scroll", listenToScroll);
      setIsLargeLogoVisible(false);
    }
  }, [location.pathname]);

  return (
    <StyledHeader>
      <LinkOne to={{ pathname: `/` }} onStartPage={isLargeLogoVisible}>
        <LogoSmall isLargeLogoVisible={isLargeLogoVisible} />
      </LinkOne>
      <HamburgerContainer />
    </StyledHeader>
  );
};

export default Header;
