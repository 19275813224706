import styled from "styled-components";

interface BackgroundProps {
  image: string;
}

export const Main = styled.main`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const MainWholePage = styled.main`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const OverlayTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 15px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 5px;
  background: rgba(19, 29, 54, 0.4);

  @media (min-width: 768px) {
    padding: 20px;
  }

  @media (min-width: 992px) {
    padding: 50px;
  }

  @media (min-width: 1200px) {
    padding: 80px;
  }
`;

export const OverlayContainer = styled.div`
  position: relative;
  margin-bottom: 50px;
  width: 100%;

  &:nth-child(2n + 1) ${OverlayTextContainer} {
    align-items: flex-end;
    text-align: right;
  }

  @media (min-width: 768px) {
    margin-bottom: 100px;
  }
`;

export const OverlayImage = styled.img`
  width: 100%;
  height: 300px;
  border-radius: 5px;
  display: block;
  object-fit: cover;
  object-position: 0 0;

  @media (min-width: 768px) {
    height: 500px;
  }

  @media (min-width: 992px) {
    height: 600px;
  }
`;

export const LinearColorDiv = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-image: linear-gradient(rgba(10, 23, 36, 1), rgba(255, 0, 0, 0) 80px);
  pointer-events: none;
  z-index: 1;
`;

export const FirstContainer = styled.section`
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 80%;
  max-width: 400px;

  @media (min-width: 992px) {
    flex-direction: row;
    max-width: unset;
    max-height: 600px;
    justify-content: flex-start;
  }
`;

export const SecondContainer = styled.section`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 80vw;
  margin-top: 100px;

  @media (min-width: 992px) {
    width: 800px;
    min-height: unset;
    margin-bottom: 100px;
  }

  @media (min-width: 1200px) {
    width: 900px;
  }
`;

export const FullScreenContainer = styled.section`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 80vw;
  margin-top: 50px;

  @media (min-width: 992px) {
    width: 800px;
  }

  @media (min-width: 1200px) {
    width: 900px;
  }
`;

export const NavButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
`;

export const TextContainer = styled.article`
  max-width: 80vw;

  @media (min-width: 992px) {
    width: 800px;
  }

  @media (min-width: 1200px) {
    width: 900px;
  }
`;

export const ServiceTextContainer = styled.div``;

export const FlipContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 992px) {
    flex-direction: row;
    grid-column: 1/-1;
    justify-content: space-between;
    ${ServiceTextContainer} {
      margin-left: 0px;
      margin-right: 30px;
    }

    &:nth-child(2n) {
      flex-direction: row-reverse;
      ${ServiceTextContainer} {
        margin-left: 30px;
        margin-right: 0px;
      }
    }
  }
`;

export const TextImageContainer = styled.article`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 50px;
  row-gap: 50px;
  margin-bottom: 50px;
  align-items: start;
  max-width: 80vw;

  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr;
    width: 800px;

    &:nth-child(2n) {
      margin-bottom: 100px;
    }
  }

  @media (min-width: 1200px) {
    width: 900px;
  }
`;

export const ImageInContainer = styled.img`
  width: 100%;
  height: 100%;
  max-height: 400px;
  border-radius: 2px;
  object-fit: cover;
  align-self: center;
`;

export const ImageInFlexContainer = styled.img`
  width: 100%;
  height: 100%;
  max-height: 400px;
  border-radius: 2px;
  object-fit: cover;
  align-self: center;

  @media (min-width: 992px) {
    width: 50%;
  }
`;

export const TextInContainer = styled.article`
  width: 100%;

  @media (min-width: 992px) {
    max-width: 400px;
    margin: 20px 0;
  }
`;

export const TextInContainerHeader = styled.article`
  width: 100%;

  @media (min-width: 992px) {
    max-width: 850px;
    margin: 20px 0;
  }
`;

export const LinkOverlayContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 60px 0 80px 0;
  width: 100%;
`;

export const BackgroundImage = styled.div<BackgroundProps>`
  height: 100vh;
  width: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: -1;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(10, 23, 36, 1)), url(${({ image }) => image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: 992px) {
    max-height: 600px;
  }
`;
