import styled from "styled-components";

export const LogoContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 40%;

  @media (min-width: 992px) {
    width: 20%;
  }

  @media (min-width: 1200px) {
    width: 15%;
  }

  @media (max-height: 576px) and (orientation: landscape) {
    width: 20%;
  }
`;
