import styled from "styled-components";

export const FormContainer = styled.div`
  background-color: white;
  width: 100%;
  border-radius: 3px;
  box-shadow: inset 0px 0px 11px 0px #404040;
  margin-top: 10px;
  padding: 10px;
  z-index: 1;

  @media (min-width: 992px) {
    width: max-content;
    margin-left: 50px;
    padding: 20px;
    min-width: 350px;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
`;
