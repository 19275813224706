import { useState } from "react";
import {
  Main,
  FirstContainer,
  SecondContainer,
  BackgroundImage,
  ImageInFlexContainer,
  TextImageContainer,
  TextInContainer,
  ContactContainer,
  NavButtonContainer,
  TextInContainerHeader,
  FlipContainer,
  ServiceTextContainer,
} from "./page.styles";
import {
  HeadingOneShadow,
  BodyThreeText,
  HeadingThree,
  HeadingOne,
  LinkOne,
  LinkArrowContentPage,
} from "../ui/text.styles";
import { mdiChevronDown, mdiArrowRightCircle } from "@mdi/js";
import Icon from "@mdi/react";
import { NavButton } from "../ui/Buttons/button.styles";
import { scrollDown } from "../utils/ClickFunctions";

export const Services = () => {
  const [isConsultingVisible, setIsConsultingVisible] = useState(true);
  return (
    <Main>
      <BackgroundImage image={`${"/images/building.jpg"}`} />
      <FirstContainer>
        <TextInContainerHeader>
          <HeadingOneShadow>Services</HeadingOneShadow>
          <BodyThreeText>
            Our team consists of top-class senior developers, project managers, product owners, tech leads, scrum-masters
            and whatever else needed to develop enterprise software on a bigger or smaller scale. No project or system is
            too big, small, complex or simple. Through fail and foul, any partner of Nomad will know that we will remain
            by their side til the very end. We are motivated, ambitious and love to work. When working with us, you work
            with a collective of knowledge, wisdom, experience and the power to build anything.
          </BodyThreeText>{" "}
        </TextInContainerHeader>
        <LinkArrowContentPage onClick={scrollDown}>
          <Icon path={mdiChevronDown} size={3} />
        </LinkArrowContentPage>
      </FirstContainer>
      <SecondContainer>
        <NavButtonContainer>
          <NavButton onClick={() => setIsConsultingVisible(true)} active={isConsultingVisible}>
            Consulting
          </NavButton>
          <NavButton onClick={() => setIsConsultingVisible(false)} active={!isConsultingVisible}>
            Startups
          </NavButton>
        </NavButtonContainer>
        {isConsultingVisible && (
          <>
            <TextImageContainer>
              <FlipContainer>
                <ServiceTextContainer>
                  <HeadingThree>What are we? </HeadingThree>
                  <TextInContainer>
                    <BodyThreeText>
                      We are software specialists much like the go-to-person that everyone loves to have on their team,
                      and we thrive in landscapes where severe technical challenges need to come out as quality code,
                      and easily maintainable software, on the other side.
                    </BodyThreeText>
                  </TextInContainer>
                </ServiceTextContainer>
                <ImageInFlexContainer src={"/images/team-discussing.jpg"} alt="logo" />
              </FlipContainer>
              <FlipContainer>
                <ServiceTextContainer>
                  <HeadingThree>What are we not? </HeadingThree>
                  <TextInContainer>
                    <BodyThreeText>
                      We are not like any of the high growth, traditional consultancy firms with complex hierarchies,
                      step-based salary models, coarse business plans and decision chains. These features are all parts
                      of the past, where companies are struggling with keeping their senior employees. All while facing
                      challenges finding and employing the stars of tomorrow. These kinds of "growth-or-nothing"
                      companies are neither suitable for taking on core development of a startup, nor assisting projects
                      that require highly skilled personnel.
                    </BodyThreeText>
                  </TextInContainer>
                </ServiceTextContainer>
                <ImageInFlexContainer src={"/images/agile.jpg"} alt="logo" />
              </FlipContainer>
              <FlipContainer>
                <ServiceTextContainer>
                  <HeadingThree>In conclusion </HeadingThree>
                  <BodyThreeText>
                    This is exactly why Nomad was created: We are entirely self-managed and have a fully transparent
                    business model, not focusing on growth, but finding the right people that we like to work and hang
                    out with. And even more importantly, that likes to work and hang out with us. This is also why, at
                    Nomad, the full focus starts with the consultant. All consultants are part of the bigger picture
                    through full responsibility for their own accounts and commissioned income as well as eventual
                    co-ownership. Together we can do anything. Let us help you. Hire a Nomad!
                  </BodyThreeText>
                </ServiceTextContainer>
                <ImageInFlexContainer src={"/images/group-on-hill.jpg"} alt="logo" />
              </FlipContainer>
            </TextImageContainer>
          </>
        )}
        {!isConsultingVisible && (
          <>
            <TextImageContainer>
              <FlipContainer>
                <ServiceTextContainer>
                  <HeadingThree>Why Nomad? </HeadingThree>
                  <BodyThreeText>
                    We are a firm with extensive experience in software and product development. We have made
                    the trip with the most innovative startups already and are well experienced with the challenges and
                    pitfalls of start- and scaleups. If you choose to partner with us, you choose a team of skilled and
                    passionate software professionals to get your startup off the ground in no time.
                  </BodyThreeText>
                </ServiceTextContainer>
                <ImageInFlexContainer src={"/images/discussing.jpg"} alt="logo" />
              </FlipContainer>
              <FlipContainer>
                <ServiceTextContainer>
                  <HeadingThree>What can you expect of us? </HeadingThree>
                  <BodyThreeText>
                    Most of the time our potential partners are ambitious and fast-moving professionals with innovative
                    thoughts and ideas, but the actual embodiment seems complex. This is where we come in. We help your
                    startup off the ground, all while tailoring the partnership around your specific needs. It does not
                    matter if it is mobile, web, cloud, UI or UX. Our network is big, and our team is proficient in all
                    parts of the stack as well as stages of product development and project management. Shed no more
                    sweat or tears over the tedious task of finding expensive contractors. Leave this to us. Let us
                    focus on what we do best, so you can focus on what you do best. 
                  </BodyThreeText>
                </ServiceTextContainer>
                <ImageInFlexContainer src={"/images/work-table.jpg"} alt="logo" />
              </FlipContainer>
              <FlipContainer>
                <ServiceTextContainer>
                  <HeadingThree>Becoming a partner </HeadingThree>
                  <BodyThreeText>
                    Our partnerships with startups are all about building long term value. We will follow your journey 
                    for as long as you want. What is good for you, is also good for us. 
                    Let us create awesomeness together!
                  </BodyThreeText>
                </ServiceTextContainer>
                <ImageInFlexContainer src={"/images/look-here.jpg"} alt="logo" />
              </FlipContainer>
            </TextImageContainer>
          </>
        )}
        <ContactContainer>
          <HeadingOne>Want to discuss possibilities? </HeadingOne>
          <LinkOne to={{ pathname: `/contact` }}>
            Contact us <Icon path={mdiArrowRightCircle} size={1} />
          </LinkOne>
        </ContactContainer>
      </SecondContainer>
    </Main>
  );
};

export default Services;
