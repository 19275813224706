import axios from "axios";
import { appConfig } from "./appConfig";

export const getMainAxiosClient = () => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  const axiosConfig = {
    baseURL: appConfig.publicConfig.mainApiUrl,
    headers: headers,
  };

  return axios.create(axiosConfig);
};
