import styled from "styled-components";
import { Link } from "react-router-dom";

interface StyleProps {
  onStartPage?: boolean;
}

export const HeadingOne = styled.h1`
  font-family: ${(props) => props.theme.fonts};
  font-size: ${(props) => props.theme.fontSizes.xHuge};
  letter-spacing: ${(props) => props.theme.letterSpacing};
  color: ${(props) => props.theme.colors.colorPalette.medium};
  font-weight: bold;
`;

export const HeadingOneShadow = styled.h1`
  font-family: ${(props) => props.theme.fonts};
  font-size: ${(props) => props.theme.fontSizes.xHuge};
  letter-spacing: ${(props) => props.theme.letterSpacing};
  color: ${(props) => props.theme.colors.colorPalette.medium};
  font-weight: bold;
  text-shadow: 0 1px 1px #000;
`;

export const HeadingTwo = styled.h2`
  font-family: ${(props) => props.theme.fonts};
  font-size: ${(props) => props.theme.fontSizes.xLarge};
  letter-spacing: ${(props) => props.theme.letterSpacing};
  color: ${(props) => props.theme.colors.colorPalette.medium};
  font-weight: bold;
`;

export const HeadingThree = styled.h3`
  font-family: ${(props) => props.theme.fonts};
  font-size: ${(props) => props.theme.fontSizes.large};
  letter-spacing: ${(props) => props.theme.letterSpacing};
  color: ${(props) => props.theme.colors.colorPalette.medium};
  font-weight: bold;
`;

export const BodyThreeText = styled.p`
  font-family: ${(props) => props.theme.fonts};
  font-size: ${(props) => props.theme.fontSizes.medium};
  letter-spacing: ${(props) => props.theme.letterSpacing};
  color: ${(props) => props.theme.colors.colorPalette.light};
`;

export const LinkOne = styled(Link)<StyleProps>`
  font-family: ${(props) => props.theme.fonts};
  font-size: ${(props) => props.theme.fontSizes.huge};
  letter-spacing: ${(props) => props.theme.letterSpacing};
  color: ${(props) => props.theme.colors.colorPalette.light};
  text-transform: uppercase;
  font-weight: bold;
  text-shadow: 0 1px 1px #000;
  text-decoration: none;

  &:hover {
    color: ${(props) => props.theme.colors.colorPalette.medium};
    cursor: ${({ onStartPage }) => (onStartPage ? "default" : "pointer")};
  }
`;

export const LinkIcon = styled.a`
  font-family: ${(props) => props.theme.fonts};
  font-size: ${(props) => props.theme.fontSizes.huge};
  letter-spacing: ${(props) => props.theme.letterSpacing};
  color: ${(props) => props.theme.colors.colorPalette.light};
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  height: max-content;
  width: max-content;
  border-radius: 50%;
  padding: 0;
  display: flex;
  padding: 10px;
  margin: 32px;
  margin-bottom: 64px;

  &:hover {
    color: ${(props) => props.theme.colors.colorPalette.medium};
  }
`;

export const SmallLinkIcon = styled.a`
  font-family: ${(props) => props.theme.fonts};
  font-size: ${(props) => props.theme.fontSizes.huge};
  letter-spacing: ${(props) => props.theme.letterSpacing};
  color: ${(props) => props.theme.colors.colorPalette.light};
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  height: max-content;
  width: max-content;
  border-radius: 50%;
  padding: 0;
  display: flex;
  padding: 10px;

  &:hover {
    color: ${(props) => props.theme.colors.colorPalette.medium};
  }
`;

export const LinkArrow = styled.a`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: ${(props) => props.theme.fonts};
  font-size: ${(props) => props.theme.fontSizes.xHuge};
  letter-spacing: ${(props) => props.theme.letterSpacing};
  color: ${(props) => props.theme.colors.colorPalette.light};
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  height: max-content;
  width: max-content;
  border-radius: 50%;
  padding: 0;

  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.colorPalette.medium};
  }
`;

export const LinkArrowContentPage = styled.a`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: ${(props) => props.theme.fonts};
  font-size: ${(props) => props.theme.fontSizes.xHuge};
  letter-spacing: ${(props) => props.theme.letterSpacing};
  color: ${(props) => props.theme.colors.colorPalette.light};
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  height: max-content;
  width: max-content;
  border-radius: 50%;
  padding: 0;

  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.colorPalette.medium};
  }

  @media (min-width: 992px) {
    display: none;
  }
`;
