export const scrollDown = () => {
  const heightScreen = window.innerHeight;
  const distanceToTop = window.pageYOffset;

  let scrollDownHeight = heightScreen - distanceToTop;

  if (scrollDownHeight < 0) {
    scrollDownHeight = 0;
  }
  window.scrollBy({
    top: scrollDownHeight,
    left: 0,
    behavior: "smooth",
  });
};
