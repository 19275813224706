import { useLocation } from "react-router-dom";
import { FlexColumn, StyledLink, StyledMenu } from "./hamburger.styles";
import { mdiLinkedin } from "@mdi/js";
import Icon from "@mdi/react";
import { LinkIcon } from "../../ui/text.styles";

interface IBurgerInfo {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const HamburgerContent: React.FC<IBurgerInfo> = (props: IBurgerInfo) => {
  const { open, setOpen } = props;
  const location = useLocation();

  return (
    <StyledMenu open={open}>
      <FlexColumn>
        <StyledLink to={{ pathname: "/" }} onClick={() => setOpen(false)} onPage={location.pathname === `/`}>
          Start
        </StyledLink>
        <StyledLink
          to={{
            pathname: `/services`,
          }}
          onClick={() => setOpen(false)}
          onPage={location.pathname === `/services`}
        >
          Services
        </StyledLink>
        <StyledLink
          to={{
            pathname: "/culture",
          }}
          onClick={() => setOpen(false)}
          onPage={location.pathname === `/culture`}
        >
          Culture
        </StyledLink>{" "}
        <StyledLink
          to={{
            pathname: "/career",
          }}
          onClick={() => setOpen(false)}
          onPage={location.pathname === `/career`}
        >
          Career
        </StyledLink>
        <StyledLink
          to={{
            pathname: "/contact",
          }}
          onClick={() => setOpen(false)}
          onPage={location.pathname === `/contact`}
        >
          Contact
        </StyledLink>
      </FlexColumn>
      <LinkIcon href={"https://www.linkedin.com/company/nomad-consulting-group/"}>
        {" "}
        <Icon path={mdiLinkedin} size={2} />
      </LinkIcon>
    </StyledMenu>
  );
};

export default HamburgerContent;
