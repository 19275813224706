import { StyledBurger } from "./hamburger.styles";

interface IBurgerInfo {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const HamburgerButton: React.FC<IBurgerInfo> = (props: IBurgerInfo) => {
  const { open, setOpen } = props;
  return (
    <StyledBurger open={open} onClick={() => setOpen(!open)}>
      <div />
      <div />
      <div />
    </StyledBurger>
  );
};

export default HamburgerButton;
