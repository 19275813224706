import { useRef, useState } from "react";
import { useOnClickOutside } from "../../hooks";
import { BurgerContainer } from "./hamburger.styles";
import HamburgerButton from "./HamburgerButton";
import HamburgerContent from "./HamburgerContent";

const HamburgerContainer: React.FunctionComponent = () => {
  const [open, setOpen] = useState(false);
  const node = useRef<HTMLDivElement>(null);
  useOnClickOutside(node, () => setOpen(false));

  return (
    <BurgerContainer ref={node}>
      <HamburgerButton open={open} setOpen={setOpen} />
      <HamburgerContent open={open} setOpen={setOpen} />
    </BurgerContainer>
  );
};

export default HamburgerContainer;
