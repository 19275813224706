import styled from "styled-components";

interface NavProps {
  active: boolean;
}

export const Button = styled.button`
  background-color: ${(props) => props.theme.colors.primaryBrandColor};
  color: ${(props) => props.theme.colors.colorPalette.dark};
  margin: 20px;
  border: none;
  border-radius: 4px;
  padding: 12px 20px;
  font-family: inherit;
  font-size: ${(props) => props.theme.fontSizes.small};

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.colorPalette.medium};
  }
`;

export const NavButton = styled.button<NavProps>`
  background-color: transparent;
  color: ${({ active }) =>
    active ? (props) => props.theme.colors.colorPalette.light : (props) => props.theme.colors.colorPalette.medium};
  margin: 12px;
  border: 0px;
  border-bottom: 2px solid ${(props) => props.theme.colors.colorPalette.medium};
  padding: 12px 20px;
  font-family: inherit;
  font-size: ${(props) => props.theme.fontSizes.xLarge};
  width: 250px;
  z-index: 3;

  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.colorPalette.light};
  }

  @media (min-width: 992px) {
    margin: 50px;
  }
`;
