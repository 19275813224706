interface AppConfig {
  publicConfig: {
    mainApiUrl: string;
  };
}

export const appConfig: AppConfig = {
  publicConfig: {
    mainApiUrl: "/",
  },
};
