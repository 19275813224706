import { useState } from "react";
import { darkTheme } from "./ui/Theme";
import { ThemeProvider } from "styled-components";
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import Start from "./pages/Start";
import Services from "./pages/Services";
import Culture from "./pages/Culture";
import Careers from "./pages/Careers";
import Contact from "./pages/Contact";
import Header from "./components/Header/Header";
import ScrollToTop from "./utils/ScrollToTop";
import Footer from "./components/Footer/Footer";

const App = () => {
  const [isLargeLogoVisible, setIsLargeLogoVisible] = useState(false);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <ThemeProvider theme={darkTheme}>
        <Header isLargeLogoVisible={isLargeLogoVisible} setIsLargeLogoVisible={setIsLargeLogoVisible} />
        <Routes>
          <Route path="/" element={<Start isLargeLogoVisible={isLargeLogoVisible} />} />
          <Route path="/services" element={<Services />} />
          <Route path="/culture" element={<Culture />} />
          <Route path="/career" element={<Careers />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        {!isLargeLogoVisible && <Footer />}
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
