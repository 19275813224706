import React, { useState } from "react";
import { BodyThreeText, HeadingOne, HeadingTwo } from "../../ui/text.styles";
import { Button } from "../../ui/Buttons/button.styles";
import { StyledTextField } from "./form-mu.styles";
import { StyledForm, FormContainer } from "./form.styles";
import { getMainAxiosClient } from "../../utils/services";

const Form: React.FunctionComponent = () => {
  const [newForm, setNewForm] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isFormSent, setIsFormSent] = useState(false);
  const axiosClient = getMainAxiosClient();

  const postForm = async () => {
    try {
      await axiosClient.post(`/contact`, newForm);
      setIsFormSent(true);
    } catch (err) {
      console.log(err);
    }
  };

  const onButtonClick = async (e: any) => {
    e.preventDefault();
    await postForm();
  };

  return (
    <>
      <FormContainer>
        <HeadingOne>Contact Us</HeadingOne>
        {!isFormSent && (
          <StyledForm>
            <StyledTextField
              onChange={(e: any) => setNewForm({ ...newForm, name: e.target.value })}
              value={newForm.name}
              label="Name"
              variant="outlined"
              required
            />
            <StyledTextField
              onChange={(e: any) => setNewForm({ ...newForm, email: e.target.value })}
              value={newForm.email}
              label="E-mail"
              variant="outlined"
              required
            />
            <StyledTextField
              onChange={(e: any) => setNewForm({ ...newForm, message: e.target.value })}
              value={newForm.message}
              label="Message"
              variant="outlined"
              minRows={4}
              multiline
              required
            />
            <Button onClick={(e: any) => onButtonClick(e)}>Send</Button>
          </StyledForm>
        )}
        {isFormSent && (
          <div>
            <HeadingTwo>Thank you for contacting us!</HeadingTwo>
            <BodyThreeText>We will respond to you as soon as possible.</BodyThreeText>
          </div>
        )}
      </FormContainer>
    </>
  );
};

export default Form;
