export interface ITheme {
  colors: {
    primaryBrandColor: string;
    backgroundColor: string;
    colorPalette: {
      dark: string;
      medium: string;
      light: string;
    };
  };
  fonts: [string];
  fontSizes: {
    tiny: string;
    xSmall: string;
    small: string;
    medium: string;
    large: string;
    xLarge: string;
    huge: string;
  };
  letterSpacing: string;
}

export const darkTheme = {
  colors: {
    primaryBrandColor: "#DE6C4D",
    backgroundColor: "#0a1724",
    colorPalette: {
      dark: "#733124",
      medium: "#DE8970",
      light: "#DFB6A6",
    },
  },
  fonts: ["Zen Kaku Gothic New"],
  fontSizes: {
    tiny: "0.5rem",
    xSmall: "0.75rem",
    small: "0.85rem",
    medium: "1rem",
    large: "1.25rem",
    xLarge: "1.5rem",
    huge: "2rem",
    xHuge: "2.5rem",
    xlHuge: "3rem",
  },
  letterSpacing: "0.024em",
};

export const lightTheme = {
  colors: {
    primaryBrandColor: "#DE6C4D",
    backgroundColor: "#f9f9f9",
    colorPalette: {
      dark: "#291D21",
      medium: "#733124",
      light: "#DE4E29",
    },
  },
  fonts: ["Zen Kaku Gothic New"],
  fontSizes: {
    tiny: "0.5rem",
    xSmall: "0.75rem",
    small: "0.85rem",
    medium: "1rem",
    large: "1.25rem",
    xLarge: "1.5rem",
    huge: "2rem",
    xHuge: "2.5rem",
    xlHuge: "3rem",
  },
  letterSpacing: "0.024em",
};
