import styled from "styled-components";

interface LogoImage {
  isLargeLogoVisible: boolean;
}

export const LogoImageLarge = styled.img<LogoImage>`
  width: 100%;
  visibility: ${({ isLargeLogoVisible }) => (isLargeLogoVisible ? "visible" : "hidden")};
  opacity: ${({ isLargeLogoVisible }) => (isLargeLogoVisible ? "1" : "0")};
  transition: visibility 4s linear, opacity 4s linear;
`;

export const LogoImageText = styled.img`
  width: 100px;
  margin-right: 16px;
`;

export const LogoImageSmall = styled.img<LogoImage>`
  width: 25px;
  margin: 25px;
  visibility: ${({ isLargeLogoVisible }) => (!isLargeLogoVisible ? "visible" : "hidden")};
  opacity: ${({ isLargeLogoVisible }) => (!isLargeLogoVisible ? "1" : "0")};
  transition: visibility 2s linear, opacity 2s linear;

  animation: fadeInAnimation 2s;

  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;
