import styled from "styled-components";
import { Link } from "react-router-dom";

interface StyleProps {
  open: boolean;
  onPage?: boolean;
}

export const BurgerContainer = styled.div`
  display: flex;
  justify-self: flex-start;
  align-items: center;
`;

export const StyledBurger = styled.button<StyleProps>`
  position: fixed;
  top: 25px;
  right: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: fit-content;
  height: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 3;

  &:focus {
    outline: none;
  }

  div {
    width: 30px;
    height: 2px;
    background: ${({ open }) =>
      open ? (props: any) => props.theme.colors.backgroundColor : (props: any) => props.theme.colors.primaryBrandColor};
    border-radius: 10px;
    transition: all 0.4s linear;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;

export const StyledMenu = styled.nav<StyleProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  background: ${(props) => props.theme.colors.primaryBrandColor};
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(+100%)")};
  text-align: center;
  position: fixed;
  top: 0;
  right: 0;
  transition: transform 0.4s ease-in-out;
  width: 100%;
  height: 100vh;

  @media (min-width: 576px) {
    width: 250px;
    border-left: 1px solid ${(props) => props.theme.colors.backgroundColor};
    text-align: right;
    align-items: flex-end;
    left: unset;
    bottom: unset;
  }

  @media (min-width: 768px) {
    width: 40vw;
  }

  @media (min-width: 992px) {
    width: 30vw;
    max-width: 400px;
  }
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledLink: any = styled(Link)<StyleProps>`
  font-family: ${(props) => props.theme.fonts};
  letter-spacing: ${(props) => props.theme.letterSpacing};
  font-size: ${(props) => props.theme.fontSizes.large};
  padding: 2rem 0;
  font-weight: 900;
  letter-spacing: 0.5rem;
  color: ${({ onPage }) =>
    onPage
      ? (props: any) => props.theme.colors.colorPalette.light
      : (props: any) => props.theme.colors.backgroundColor};
  text-decoration: none;
  transition: color 0.3s linear;

  &:hover {
    color: ${(props) => props.theme.colors.colorPalette.light};
  }

  &:first-child {
    margin-top: 60px;
  }

  @media (min-width: 576px) {
    padding: 2rem;
    font-size: ${(props) => props.theme.fontSizes.xLarge};
  }
`;
