import {
  Main,
  FirstContainer,
  SecondContainer,
  BackgroundImage,
  ImageInContainer,
  TextImageContainer,
  TextInContainer,
  ContactContainer,
  TextInContainerHeader,
} from "./page.styles";
import careerImage from "../images/office.jpg";
import {
  BodyThreeText,
  HeadingOne,
  HeadingOneShadow,
  HeadingTwo,
  LinkArrowContentPage,
  LinkOne,
} from "../ui/text.styles";
import { mdiChevronDown, mdiArrowRightCircle } from "@mdi/js";
import Icon from "@mdi/react";
import { scrollDown } from "../utils/ClickFunctions";

export const Careers = () => {
  return (
    <Main>
      <BackgroundImage image={`${"/images/office.jpg"}`} />
      <FirstContainer>
        <TextInContainerHeader>
          <HeadingOneShadow>Career with Nomad</HeadingOneShadow>
          <BodyThreeText>
            We look for talented individuals willing to improve. Team players that aren't afraid to get their hands
            dirty taking on the leader-shirt, but who at the same time are responsive to feedback and without prestige.
            We look for people who like to get things done, and who always find ways of solving the problems at hand. We
            are looking for people whom we can have a meaningful non-work-related discussion with over a beer on Fridays
            after work.
          </BodyThreeText>
        </TextInContainerHeader>
        <LinkArrowContentPage onClick={scrollDown}>
          <Icon path={mdiChevronDown} size={3} />
        </LinkArrowContentPage>
      </FirstContainer>
      <SecondContainer>
        <div>
          <HeadingTwo>What we offer</HeadingTwo>
          <TextImageContainer>
            <div>
              <TextInContainer>
                <BodyThreeText>
                  Nomad is probably not anything like the consultancy companies you know of, to date! Aside from being
                  around the best people in the industry and doing a ton of fun things, we offer an opportunity to be
                  part of the company at its core.
                </BodyThreeText>

                <BodyThreeText>
                  Aside from a bunch of guarantees and "the same things you get
                  everywhere", we apply an account-based salary model where each employee has the opportunity to manage 
                  their own account, vacation, pension, salary and expenses with optional supervision. 
                  Tools are also provided for the best possible maintenance and administration of
                  these. The second part of the offer, which usually kicks in after a few years, consists of
                  co-ownership and extended beneficial partnerships.
                </BodyThreeText>
              </TextInContainer>
            </div>
            <ImageInContainer src={"/images/office.jpg"} alt="logo" />
          </TextImageContainer>
        </div>
        <ContactContainer>
          <HeadingOne>Want to know more and/or become a Nomad? </HeadingOne>
          <LinkOne to={{ pathname: `/contact` }}>
            Contact us <Icon path={mdiArrowRightCircle} size={1} />
          </LinkOne>
        </ContactContainer>
      </SecondContainer>
    </Main>
  );
};

export default Careers;
