import { LogoContainer } from "../app.styles";
import LogoDark from "../layout/logo/LogoDark";
import { Main, OverlayContainer, OverlayImage, OverlayTextContainer, SecondContainer } from "./page.styles";
import { LinkArrow, LinkOne } from "../ui/text.styles";
import { mdiChevronDown, mdiArrowRightCircle } from "@mdi/js";
import Icon from "@mdi/react";
import { scrollDown } from "../utils/ClickFunctions";

interface IStart {
  isLargeLogoVisible: boolean;
}

const Start: React.FC<IStart> = (props: IStart) => {
  const { isLargeLogoVisible } = props;
  return (
    <Main>
      <LogoContainer>
        <LogoDark isLargeLogoVisible={isLargeLogoVisible} />
        <LinkArrow onClick={scrollDown}>
          <Icon path={mdiChevronDown} size={4} />
        </LinkArrow>
      </LogoContainer>
      <SecondContainer>
        <OverlayContainer>
          <OverlayTextContainer>
            <LinkOne to={{ pathname: `/services` }}>
              Our services <Icon path={mdiArrowRightCircle} size={1} />
            </LinkOne>
          </OverlayTextContainer>
          <OverlayImage src={"/images/building.jpg"} alt="logo" />
        </OverlayContainer>
        <OverlayContainer>
          <OverlayTextContainer>
            <LinkOne to={{ pathname: `/culture` }}>
              Culture <Icon path={mdiArrowRightCircle} size={1} />
            </LinkOne>
          </OverlayTextContainer>
          <OverlayImage src={"/images/teamwork.jpg"} alt="logo" />
        </OverlayContainer>
        <OverlayContainer>
          <OverlayTextContainer>
            <LinkOne to={{ pathname: `/career` }}>
              Career <Icon path={mdiArrowRightCircle} size={1} />
            </LinkOne>
          </OverlayTextContainer>
          <OverlayImage src={"/images/office.jpg"} alt="logo" />
        </OverlayContainer>
        <OverlayContainer>
          <OverlayTextContainer>
            <LinkOne to={{ pathname: `/contact` }}>
              Contact us <Icon path={mdiArrowRightCircle} size={1} />
            </LinkOne>
          </OverlayTextContainer>
          <OverlayImage src={"/images/pen-on-book.jpg"} alt="logo" />
        </OverlayContainer>
      </SecondContainer>
    </Main>
  );
};

export default Start;
