import React from "react";
import logoDark from "../nomad-logotype-dark.svg";
import { LogoImageLarge } from "./logo.styles";
interface ILogoDark {
  isLargeLogoVisible: boolean;
}

const LogoDark: React.FC<ILogoDark> = (props: ILogoDark) => {
  const { isLargeLogoVisible } = props;
  return <LogoImageLarge src={logoDark} alt="logo" isLargeLogoVisible={isLargeLogoVisible} />;
};

export default LogoDark;
