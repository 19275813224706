import { Main, BackgroundImage, FullScreenContainer, MainWholePage } from "./page.styles";
import contactImage from "../images/pen-on-book.jpg";
import Form from "../components/Form/Form";

export const Contact = () => {
  return (
    <MainWholePage>
      <BackgroundImage image={`${"/images/pen-on-book.jpg"}`} />
      <FullScreenContainer>
        <Form />
      </FullScreenContainer>
    </MainWholePage>
  );
};

export default Contact;
