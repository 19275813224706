import React from "react";
import logoDark from "../nomad-logotype-dark.svg";
import { LogoImageSmall } from "./logo.styles";

interface ILogoSmall {
  isLargeLogoVisible: boolean;
}

const LogoSmall: React.FC<ILogoSmall> = (props: ILogoSmall) => {
  const { isLargeLogoVisible } = props;
  return <LogoImageSmall src={logoDark} alt="small-logo" isLargeLogoVisible={isLargeLogoVisible} />;
};

export default LogoSmall;
