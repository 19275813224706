import { mdiLinkedin } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import LogoText from "../../layout/logo/LogoText";
import { BodyThreeText, LinkIcon, SmallLinkIcon } from "../../ui/text.styles";
import { StyledFooter } from "./footer.style";

const Footer = () => {
  return (
    <StyledFooter>
      <LogoText />
      <SmallLinkIcon href={"https://www.linkedin.com/company/nomad-consulting-group/"}>
        {" "}
        <Icon path={mdiLinkedin} size={2} />
      </SmallLinkIcon>
    </StyledFooter>
  );
};

export default Footer;
