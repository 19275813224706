import styled from "styled-components";

export const StyledFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 80vw;
  margin-left: auto;
  margin-right: auto;
  height: 128px;

  @media (min-width: 1200px) {
    width: 900px;
  }
`;
